<template>
    <div>
        <Tabs class="pt-tabs" name="composer" :value="selectedTab" :animated="false">
            <TabPane label="Leave Request" name="plan" tab="composer">
                <LeaveRequest></LeaveRequest>
            </TabPane>
            <TabPane label="Leave Credits" name="wrapup" tab="composer">
                <LeaveCredit></LeaveCredit>
            </TabPane>
        </Tabs>
    </div>
</template>

<script>
import { getPaginatedRequestRecordsByUser } from '@/services/requests.services';
import moment from 'moment';
import { checkIfFirstAttendancePeriod, endOfDate, startOfDate } from '../../../../../utility/helper';
import LeaveRequest from './LeaveRequest/index.vue';
import LeaveCredit from './LeaveCredit/index.vue';

export default {
    components: {
        LeaveRequest,
        LeaveCredit,
    },
    data() {
        return {
            isLoading: false,
            requestList: [],
            selectedStatus: this.$route.query.status,
            selectedType: null,
            selectedDates: null,
            selectedUser: localStorage.getItem('user_id'),
            currentPage: 1,
            totalCount: null,
            isFilterClick: false,
            totalPages: 0,
            limit: 15,
            totalPerPage: {},
            btnLabel: 'New Request',
            isDisable: true,
        };
    },
    created() {
        if (!this.selectedDates) {
            const actualDatePeriod = this.checkPayPeriod();
            this.selectedDates = {
                from: startOfDate(actualDatePeriod[0]),
                to: endOfDate(actualDatePeriod[1]),
            };
        }
        if (this.$route.query.status) {
            this.handleOnStatusChanged(this.$route.query.status);
        }
        if (this.selectedType) {
            this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
        }
    },

    methods: {
        checkPayPeriod() {
            const yearMonth = moment().format('yyyy-MM');
            console.log('checkIfDateFirstPeriod: ', checkIfFirstAttendancePeriod());
            const currentDate = moment().format('yyyy-MM-DD');
            return checkIfFirstAttendancePeriod()
                ? [`${yearMonth}-01`, moment(currentDate).endOf('month').format('yyyy-MM-DD')]
                : [`${yearMonth}-16`, moment(currentDate).endOf('month').format('yyyy-MM-DD')];
        },
        handleGetAttendanceByStatus(status, date) {
            this.isLoading = true;
            this.requestList = [];
            getPaginatedRequestRecordsByUser(
                this.selectedUser,
                {
                    status: status,
                    ...(this.selectedType && { type: this.selectedType }),
                    ...(date != null &&
                        this.selectedType.includes('timedispute') && {
                            requestedStart: { $gte: date.from, $lte: date.to },
                        }),
                    ...(date != null &&
                        this.selectedType.includes('leave') && { createdAt: { $gte: date.from, $lte: date.to } }),
                },

                this.currentPage,
                this.limit
            )
                .then((res) => {
                    if (res.data.data.length) {
                        this.isLoading = true;

                        this.totalPerPage = res.data.total;
                        this.requestList = res.data.data.filter((i) => i.status == status);
                    } else {
                        this.requestList = [];
                    }
                    this.totalCount = res.data.totalCount;
                    this.totalPages = res.data.totalPages;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get requests data by status and user');
                    this.requestList = [];
                    this.isLoading = false;
                });
        },
        handleOnStatusChanged(status) {
            this.currentPage = 1;
            this.totalPages = 1;
            this.selectedStatus = status;
            if (status) {
                this.handleGetAttendanceByStatus(status, this.selectedDates);
            } else {
                this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
            }
        },
        handleReload() {
            if (this.selectedStatus) {
                this.handleGetAttendanceByStatus(this.selectedStatus, this.selectedDates);
            } else {
                this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
            }
        },
        handleGetAttendanceLogsByUser(userId, date) {
            this.isLoading = true;
            this.requestList = [];
            getPaginatedRequestRecordsByUser(
                userId,
                {
                    ...(this.selectedType && { type: this.selectedType }),
                    ...(date != null &&
                        this.selectedType.includes('timedispute') && {
                            requestedStart: { $gte: date.from, $lte: date.to },
                        }),
                    ...(date != null &&
                        this.selectedType.includes('leave') && { createdAt: { $gte: date.from, $lte: date.to } }),
                },
                this.currentPage,
                this.limit
            )
                .then((res) => {
                    console.log('handleGetAttendanceLogsByUser: ww', res.data);
                    if (res.data.data.length) {
                        this.totalPerPage = res.data.total;

                        this.requestList = res.data.data.filter((i) => i.user._id == userId);
                    } else {
                        this.requestList = [];
                    }
                    this.totalPages = res.data.totalPages;
                    this.totalCount = res.data.totalCount;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get requests data by user');
                    this.requestList = [];
                    this.isLoading = false;
                });
        },
        handleOnPageChanged(page) {
            if (this.selectedStatus) {
                this.currentPage = page;
                this.handleGetAttendanceByStatus(this.selectedStatus, this.selectedDates);
            } else {
                this.currentPage = page;
                this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
            }
        },
    },
};
</script>
<style lang="scss">
.requests-wrapper {
    .ivu-col {
        button {
            border-radius: 15px;
        }
    }
    .title-wrapper {
        display: block;

        .ivu-col {
            max-width: 100% !important;
        }
        .text-right {
            text-align: left;
            padding-top: 0 !important;
        }
    }
}
</style>
