<template>
    <div>
        <Header
            :selected-dates="selectedDates"
            @status-changed="handleOnStatusChanged"
            @date-change="handleDateChange"
        />
        <Col class="d-flex-center">
            <Button class="pt-btn-3 d-flex-center text-center" style="margin: 10px" @click="handleCreateButton">
                <Icon type="md-add" size="20" /> New Leave Request
            </Button>
        </Col>
        <List
            :total-count="totalCount"
            :current="currentPage"
            :total-pages="totalPages"
            :limit="limit"
            :requests-data-list="requestList"
            @on-page-change="handleOnPageChanged"
            @reload="handleReload"
        />
        <CreateModal ref="CreateRequest" @create="handleReload"></CreateModal>
    </div>
</template>

<script>
import CreateModal from './create.vue';
import Header from './header.vue';
import List from './list.vue';
import { checkIfFirstAttendancePeriod, endOfDate, startOfDate } from '../../../../../../utility/helper';
import moment from 'moment/moment';
import { getPaginatedRequestRecordsByUser } from '@/services/requests.services';
export default {
    components: {
        Header,
        List,
        CreateModal,
    },
    data() {
        return {
            isLoading: false,
            requestList: [],
            selectedStatus: this.$route.query.status,
            selectedType: null,
            selectedDates: null,
            requestsDataList: [],
            selectedUser: localStorage.getItem('user_id'),
            currentPage: 1,
            totalCount: null,
            isFilterClick: false,
            totalPages: 0,
            limit: 10,
            totalPerPage: {},
            btnLabel: 'New Request',
            isDisable: true,
        };
    },
    created() {
        if (!this.selectedDates) {
            const actualDatePeriod = this.checkPayPeriod();
            this.selectedDates = {
                from: startOfDate(actualDatePeriod[0]),
                to: endOfDate(actualDatePeriod[1]),
            };
        }
        if (this.$route.query.status) {
            this.handleOnStatusChanged(this.$route.query.status);
        }
        if (this.selectedType) {
            this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
        }
    },

    methods: {
        checkPayPeriod() {
            const yearMonth = moment().format('yyyy-MM');
            console.log('checkIfDateFirstPeriod: ', checkIfFirstAttendancePeriod());
            const currentDate = moment().format('yyyy-MM-DD');
            return checkIfFirstAttendancePeriod()
                ? [`${yearMonth}-01`, moment(currentDate).endOf('month').format('yyyy-MM-DD')]
                : [`${yearMonth}-16`, moment(currentDate).endOf('month').format('yyyy-MM-DD')];
        },
        showLeaveRequestForm() {
            this.$refs.CreateRequest.openModal({ userId: this.selectedUser }, false);
        },
        handleCreateButton() {
            this.$refs.CreateRequest.openModal({ userId: this.selectedUser }, false);
        },
        handleGetAttendanceByStatus(status, date) {
            this.isLoading = true;
            this.requestList = [];
            getPaginatedRequestRecordsByUser(
                this.selectedUser,
                {
                    status: status,
                    type: 'leave',
                    ...(date != null && { createdAt: { $gte: date.from, $lte: date.to } }),
                },

                this.currentPage,
                this.limit
            )
                .then((res) => {
                    if (res.data.data.length) {
                        this.isLoading = true;

                        this.totalPerPage = res.data.total;
                        this.requestList = res.data.data.filter((i) => i.status == status);
                    } else {
                        this.requestList = [];
                    }
                    this.totalCount = res.data.totalCount;
                    this.totalPages = res.data.totalPages;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get requests data by status and user');
                    this.requestList = [];
                    this.isLoading = false;
                });
        },
        handleOnStatusChanged(status) {
            console.log('status: ', status);
            this.currentPage = 1;
            this.totalPages = 1;
            this.selectedStatus = status;
            if (status) {
                this.handleGetAttendanceByStatus(status, this.selectedDates);
            } else {
                this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
            }
        },
        async handleDateChange(date) {
            console.log('date changed: ', date);
            this.currentPage = 1;
            this.totalPages = 1;
            if (date.from) this.selectedDates = { from: startOfDate(date.from), to: endOfDate(date.to) };
            else {
                this.selectedDates = null;
            }
            await this.handleReload();
        },
        handleReload() {
            if (this.selectedStatus) {
                this.handleGetAttendanceByStatus(this.selectedStatus, this.selectedDates);
            } else {
                this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
            }
        },
        handleGetAttendanceLogsByUser(userId, date) {
            this.isLoading = true;
            this.requestList = [];
            getPaginatedRequestRecordsByUser(
                userId,
                {
                    type: 'leave',
                    ...(date != null && { createdAt: { $gte: date.from, $lte: date.to } }),
                },
                this.currentPage,
                this.limit
            )
                .then((res) => {
                    console.log('handleGetAttendanceLogsByUser: ww', res.data);
                    if (res.data.data.length) {
                        this.totalPerPage = res.data.total;

                        this.requestList = res.data.data;
                    } else {
                        this.requestList = [];
                    }
                    this.totalPages = res.data.totalPages;
                    this.totalCount = res.data.totalCount;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get requests data by user');
                    this.requestList = [];
                    this.isLoading = false;
                });
        },
        handleOnPageChanged(page) {
            if (this.selectedStatus) {
                this.currentPage = page;
                this.handleGetAttendanceByStatus(this.selectedStatus, this.selectedDates);
            } else {
                this.currentPage = page;
                this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
            }
        },
    },
};
</script>
<style lang="scss">
@media screen and (max-width: 767px) {
    .requests-filter {
        .ivu-row {
            display: block;

            .ivu-col {
                max-width: 100%;
            }
            .ivu-col-span-xs-2 {
                margin: 5px 0;
            }
            .filter-btn button {
                width: 100%;
            }
        }
    }
}
</style>
