<template>
    <div class="requests-wrapper">
        <Row type="flex" justify="space-between" class="code-row-bg title-wrapper">
            <Col span="6">
                <Card class="page-title" dis-hover :bordered="false"> REQUESTS </Card>
            </Col>
        </Row>
        <Row type="flex" justify="space-between" style="margin-bottom: 20px">
            <Col class="d-flex-center">
                <span style="margin-right: 20px; width: 60%">Select request type:</span>
                <Select
                    v-model="selectedType"
                    filterable
                    clearable
                    :value="$route.query.type"
                    @on-change="onTypeChange"
                >
                    <Option v-for="item in requestTypes" :key="item.value" :value="item.value">
                        {{ item.name }}
                    </Option>
                </Select>
            </Col>
            <Col class="d-flex-center" v-if="selectedType !== 'leave'">
                <Button class="pt-btn-3 d-flex-center text-center" @click="handleCreateButton" :disabled="isDisable">
                    <Icon type="md-add" size="20" /> {{ btnLabel }}
                </Button>
            </Col>
        </Row>
        <TimeDisputeFilter
            v-if="selectedType && selectedType.includes('timedispute')"
            style="margin-bottom: 20px"
            @status-changed="handleOnStatusChanged"
            :selected-dates="selectedDates"
            @date-change="handleDateChange"
        />
        <TimeDisputeList
            v-if="selectedType && selectedType.includes('timedispute')"
            :requests-data-list="requestList"
            :is-loading="isLoading"
            :total-count="totalCount"
            :current-page="currentPage"
            :total-per-page="limit"
            :limit="limit"
            @reload="handleReload"
            @on-page-change="handleOnPageChanged"
        />
        <!--      <LeaveForm-->
        <!--          v-if="selectedType && selectedType.includes('leave')"-->
        <!--          :requests-data-list="requestList"-->
        <!--          :is-loading="isLoading"-->
        <!--          @status-changed="handleOnStatusChanged"-->
        <!--          @date-change="handleDateChange"-->
        <!--          :total-count="totalCount"-->
        <!--          :selected-dates="selectedDates"-->
        <!--          :current-page="currentPage"-->
        <!--          :limit="limit"-->
        <!--          @on-page-change="handleOnPageChanged"-->
        <!--          :total-per-page="totalPerPage" ref="CreateLeaveRequest" @reload="handleReload" />-->

        <Leave v-if="selectedType && selectedType.includes('leave')"></Leave>

        <CreateRequestModal ref="CreateRequest" @create="handleReload" />
    </div>
</template>

<script>
import TimeDisputeFilter from '@/components/Requests/Dev/TimeDisputeFilter';
import TimeDisputeList from '@/components/Requests/Dev/TimeDisputeList';
import Leave from '@/components/Requests/Dev/Leave/index.vue';
import CreateRequestModal from '@/components/Requests/Dev/TimeDispute/CreateRequestModal';
import { getPaginatedRequestRecordsByUser } from '@/services/requests.services';
import moment from 'moment';
import { checkIfFirstAttendancePeriod, endOfDate, startOfDate } from '../../utility/helper';

export default {
    components: {
        Leave,
        TimeDisputeFilter,
        TimeDisputeList,
        CreateRequestModal,
    },
    data() {
        return {
            isLoading: false,
            requestList: [],
            selectedStatus: this.$route.query.status,
            selectedType: null,
            selectedDates: null,
            selectedUser: localStorage.getItem('user_id'),
            currentPage: 1,
            totalCount: null,
            isFilterClick: false,
            totalPages: 0,
            limit: 15,
            totalPerPage: {},
            btnLabel: 'New Request',
            isDisable: true,
        };
    },
    created() {
        if (!this.selectedDates) {
            const actualDatePeriod = this.checkPayPeriod();
            this.selectedDates = {
                from: startOfDate(actualDatePeriod[0]),
                to: endOfDate(actualDatePeriod[1]),
            };
        }
        if (this.$route.query.status) {
            this.handleOnStatusChanged(this.$route.query.status);
        }
        if (this.selectedType) {
            this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
        }
    },

    methods: {
        onTypeChange(type) {
            this.selectedStatus = null;
            this.selectedType = type;
            let label = 'New Request';
            switch (type) {
                case 'timedispute':
                    label = 'New Time Disputes';
                    this.isDisable = false;
                    break;
                case 'leave':
                    label = 'New Leave Request';
                    this.isDisable = false;
                    break;
                case 'loan':
                    label = 'New Loan Request';
                    this.isDisable = false;
                    break;
                case 'coerequest':
                    label = 'New COE Request';
                    this.isDisable = false;
                    break;
                default:
                    label = 'New Request';
                    this.isDisable = true;
                    break;
            }
            this.btnLabel = label;
            this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
        },
        checkPayPeriod() {
            const yearMonth = moment().format('yyyy-MM');
            console.log('checkIfDateFirstPeriod: ', checkIfFirstAttendancePeriod());
            const currentDate = moment().format('yyyy-MM-DD');
            return checkIfFirstAttendancePeriod()
                ? [`${yearMonth}-01`, moment(currentDate).endOf('month').format('yyyy-MM-DD')]
                : [`${yearMonth}-16`, moment(currentDate).endOf('month').format('yyyy-MM-DD')];
        },
        handleCreateButton() {
            switch (this.selectedType) {
                case 'timedispute':
                    this.$refs.CreateRequest.openModal({ userId: this.selectedUser }, false);
                    break;
                case 'leave':
                    // this.$refs.CreateLeaveRequest.openModal({ userId: this.selectedUser }, false);
                    // console.log('test');
                    this.$refs.leaveModule.handleOpenLeaveRequest();
                    // this.$refs.CreateRequest.openModal({ userId: this.selectedUser }, false);
                    break;
                case 'loan':
                    break;
                case 'coerequest':
                    break;
                default:
                    break;
            }
        },
        handleGetAttendanceByStatus(status, date) {
            this.isLoading = true;
            this.requestList = [];
            getPaginatedRequestRecordsByUser(
                this.selectedUser,
                {
                    status: status,
                    ...(this.selectedType && { type: this.selectedType }),
                    ...(date != null &&
                        this.selectedType.includes('timedispute') && {
                            requestedStart: { $gte: date.from, $lte: date.to },
                        }),
                    ...(date != null &&
                        this.selectedType.includes('leave') && { createdAt: { $gte: date.from, $lte: date.to } }),
                },

                this.currentPage,
                this.limit
            )
                .then((res) => {
                    if (res.data.data.length) {
                        this.isLoading = true;

                        this.totalPerPage = res.data.total;
                        this.requestList = res.data.data.filter((i) => i.status == status);
                    } else {
                        this.requestList = [];
                    }
                    this.totalCount = res.data.totalCount;
                    this.totalPages = res.data.totalPages;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get requests data by status and user');
                    this.requestList = [];
                    this.isLoading = false;
                });
        },
        handleOnStatusChanged(status) {
            this.currentPage = 1;
            this.totalPages = 1;
            this.selectedStatus = status;
            if (status) {
                this.handleGetAttendanceByStatus(status, this.selectedDates);
            } else {
                this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
            }
        },
        async handleDateChange(date) {
            this.currentPage = 1;
            this.totalPages = 1;
            if (date.from) this.selectedDates = { from: startOfDate(date.from), to: endOfDate(date.to) };
            else {
                this.selectedDates = null;
            }
            await this.handleReload();
        },
        handleReload() {
            if (this.selectedStatus) {
                this.handleGetAttendanceByStatus(this.selectedStatus, this.selectedDates);
            } else {
                this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
            }
        },
        handleGetAttendanceLogsByUser(userId, date) {
            this.isLoading = true;
            this.requestList = [];
            getPaginatedRequestRecordsByUser(
                userId,
                {
                    ...(this.selectedType && { type: this.selectedType }),
                    ...(date != null &&
                        this.selectedType.includes('timedispute') && {
                            requestedStart: { $gte: date.from, $lte: date.to },
                        }),
                    ...(date != null &&
                        this.selectedType.includes('leave') && { createdAt: { $gte: date.from, $lte: date.to } }),
                },
                this.currentPage,
                this.limit
            )
                .then((res) => {
                    console.log('handleGetAttendanceLogsByUser: ww', res.data);
                    if (res.data.data.length) {
                        this.totalPerPage = res.data.total;

                        this.requestList = res.data.data.filter((i) => i.user._id == userId);
                    } else {
                        this.requestList = [];
                    }
                    this.totalPages = res.data.totalPages;
                    this.totalCount = res.data.totalCount;
                    this.isLoading = false;
                })
                .catch(() => {
                    this.$Message.error('Failed to get requests data by user');
                    this.requestList = [];
                    this.isLoading = false;
                });
        },
        handleOnPageChanged(page) {
            if (this.selectedStatus) {
                this.currentPage = page;
                this.handleGetAttendanceByStatus(this.selectedStatus, this.selectedDates);
            } else {
                this.currentPage = page;
                this.handleGetAttendanceLogsByUser(this.selectedUser, this.selectedDates);
            }
        },
    },
};
</script>
<style lang="scss">
.requests-wrapper {
    .ivu-col {
        button {
            border-radius: 15px;
        }
    }
    .title-wrapper {
        display: block;

        .ivu-col {
            max-width: 100% !important;
        }
        .text-right {
            text-align: left;
            padding-top: 0 !important;
        }
    }
}
</style>
